import Vue from "vue";
export default {
  state: () => ({
    multipage: true,
  }),
  mutations: {
    SET_MULTI_PAGE(state, multipageFlag) {
      Vue.ls.set("DEFAULT_MULTI_PAGE", multipageFlag);
      state.multipage = multipageFlag;
    },
  },
  actions: {
    ToggleMultipage({ commit }, multipageFlag) {
      commit("SET_MULTI_PAGE", multipageFlag);
    },
  },
};
