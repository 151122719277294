import VueRouter from "vue-router";

const routes = [
  {
    path: "/",
    redirect: "/home",
    component: () => import("@/layouts/BaseLayout"),
    children: [
      { path: "home", meta: { title: "首页" }, component: () => import("@/views/home") },
      { path: "clue", meta: { title: "线索管理" }, component: () => import("@/views/lead") },
      { path: "public_clue", meta: { title: "公海客户" }, component: () => import("@/views/publicLead") },
      { path: "application", meta: { title: "客户账号申请" }, component: () => import("@/views/application") },
      { path: "account", meta: { title: "客户账号管理" }, component: () => import("@/views/account") },
      { path: "user", meta: { title: "用户管理" }, component: () => import("@/views/user") },
    ],
  },
  {
    path: "/user",
    component: () => import("@/layouts/UserLayout"),
    children: [
      { path: "login", meta: { title: "首页" }, component: () => import("@/views/login") },
      { path: "set_password", meta: { title: "首页" }, component: () => import("@/views/setPassword") },
    ],
  },
];

export default new VueRouter({ mode: "history", routes });
